<template>
  <b-container fluid>
    <ValidationObserver ref="payFromDebtObserver" v-slot="{ invalid }">
      <form>
        <b-row>
          <b-form-group class="col-md-6" :label="$t('Amount')" label-for="amount">
            <b-form-input :value="model.rowTotal+' '+debtCurrencySymbol"
                          disabled="disabled">
            </b-form-input>
          </b-form-group>
        </b-row>
        <b-row>
          <b-form-group class="col-md-6" :label="$t('AmountPayable')" label-for="amountPayable">
            <ValidationProvider :name="$t('AmountPayable')" rules="required" v-slot="{ errors }">
              <b-form-input :class="(errors.length > 0 ? ' is-invalid' : '')"
                            v-model="payRowTotal" @keypress="$root.isNumber"></b-form-input>
              <b-form-invalid-feedback>{{ $t('ValidationMessage.CannotBeEmpty') }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-row>
        <br />
        <b-row>
          <b-form-group class="col-md-6" :label="$t('DebtDate')" label-for="debtDate">
            <b-form-input :value="model.payDate | dateFormat"
                          disabled="disabled"></b-form-input>
          </b-form-group>
          <b-form-group class="col-md-6" :label="$t('PayDate')" label-for="payDate">
            <date-picker v-model="payPayDate"
                         :tag-name="'payPayDate'"
                         :is-required="true"
                         :disabled="canCreateWithPastOrFutureDate" />

          </b-form-group>
        </b-row>
        <b-row>
          <b-form-group class="col-md-12" :label="$t('Note')" label-for="note">
            <b-textarea v-model="model.notes" rows="2"></b-textarea>
          </b-form-group>
        </b-row>
        <b-row>
          <b-form-group class="col-md-4" :label="$t('BankInfoType')" label-for="bankInfoType">
            <ValidationProvider :name="$t('BankInfoType')" rules="required" v-slot="{ errors }">
              <v-select transition="" v-model="bankInfoTypeId"
                        :clearable="false"
                        :reduce="s => s.id" label="name"
                        :options="bankInfoTypes"
                        :class="(errors.length > 0 ? ' is-invalid' : '')"
                        required>
              <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
              <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>

          <b-form-group v-if="bankInfoSection" class="col-md-4" :label="$t('BankInfo')" label-for="bankInfo">
            <ValidationProvider :name="$t('BankInfo')" rules="required" v-slot="{ errors }">
              <v-select v-model="bankInfoId"
                        :reduce="s => s.id" label="name"
                        :options="bankInfos"
                        :class="(errors.length > 0 ? ' is-invalid' : '')"
                        required>
              <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
              <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </b-row>
        <modal-footer-button :set-is-submitting="isSubmitting"
                             @submit="payFromDebt"></modal-footer-button>
      </form>
    </ValidationObserver>
  </b-container>
</template>

<script>
  import { refreshSmartDuoData } from '../../../helpers/dataRefresher'
  import { getToday } from '../../../utils/dateTimeExtensions'

  import accountingService from '../../../services/accounting'
  import commonService from '../../../services/common'

  import DatePicker from '../../../components/shared/DatePicker'
  import ModalFooterButton from '../../../components/shared/ModalFooterButton'

  export default {
    name: 'PayFromDebt',
    components: {
      DatePicker,
      ModalFooterButton
    },
    props: {
      accountingDebtId: String
    },
    data() {
      return {
        model: {
          id: '',
          payDate: null,
          currencyID: this.$store.getters['Auth/tenantInfo'].mainCurrencyId,
          rowTotal: '',
          notes: ''
        },
        bankInfoTypeId: 6,
        bankInfoId: '',
        payPayDate: getToday(),
        payRowTotal: 0,

        debtCurrency: 1,//modelin currencyId'sini bu değere atayıp farklı bir kur ile ödeme yapılmak istendiğinde elimde olmasını sağlıyorum
        payRowTotalConvertToDebt: 0,
        debtCurrencySymbol: '',

        currencies: [],
        bankInfos: [],
        bankInfoTypes: [],

        bankInfoSection: false,
        isSubmitting: false
      };
    },
    methods: {
      async payFromDebt() {
        const isValid = await this.$refs.payFromDebtObserver.validate();
        let check = this.comparePayRowTotalAndRowTotal();
        let isSelectedPayment = this.hasPayment;

        if (!isValid) {
          this.$toastr.error(this.$t('ValidationMessage.EmptyAndValidateError'));
          return;
        }

        if (isValid && check && isSelectedPayment) {
          let postParams = {
            accountingDebtId: this.model.id,
            payDate: this.payPayDate,
            rowTotal: this.payRowTotal,
            bankInfoTypeId: this.bankInfoTypeId,
            bankInfoId: this.bankInfoId,
            notes: this.model.notes,
            currencyId: this.model.currencyID
          }

          this.isSubmitting = true;

          accountingService.payFromDebt(postParams).then((result) => {
            this.isSubmitting = false;
            if (result) {
              this.$emit('closeModal');
              this.$toastr.success(this.$t('Success'));
              refreshSmartDuoData();
            } else {
              this.$toastr.error(this.$t('Error'));
            }
          }).finally(() => { this.isSubmitting = false; })
        }
      },
      getDebt() {
        if (this.model.id && this.model.id.length > 0) {
          accountingService.getDebt(this.model.id)
            .then((response) => {
              this.model = response;
              this.debtCurrency = this.model.currencyID;
              this.payRowTotal = this.model.rowTotal;
            })
            .finally(() => {
              this.getBankInfoTypes();
              this.getBankInfos();
              this.getCurrencies();
            });
        }
      },
      getBankInfoTypes() {
        accountingService.getBankInfoTypes()
          .then((response) => {
            if (response && response.length > 0)
              this.bankInfoTypes = response.filter(z => z.id != 3 && z.id != 12 && z.id != 7);
          });
      },
      getBankInfos() {
        accountingService.getBankInfos(this.bankInfoTypeId).then((response) => {
          if (response) {
            this.bankInfos = response;
            if (this.bankInfos && this.bankInfos.length > 0)
              this.bankInfoSection = true;
            else
              this.bankInfoSection = false;
          }
        })
      },
      getCurrencies() {
        commonService.getCurrencyList()
          .then(response => {
            this.currencies = response;
            if (this.currencies && this.currencies.length > 0)
              this.debtCurrencySymbol = this.currencies.find(s => s.currencyId == this.model.currencyID).symbol;
          });
      },
      calculatePayRowTotal() {
        this.payRowTotalConvertToDebt = -1 * this.payRowTotal;
      },
      comparePayRowTotalAndRowTotal() {
        if (this.payRowTotal != 0) {
          if ((this.payRowTotalConvertToDebt * -1) <= this.model.rowTotal) {
            return true;
          } else {
            this.$toastr.warning(this.$t("CannotEnterGreaterThanDebt"));
            return false;
          }
        }
        else {
          this.$toastr.warning(this.$t("AmountCannotEqualToZero"));
        }
      }
    },
    computed: {
      debtCurrenyId() {
        return this.model.currencyID;
      },
      hasPayment: function () {
        let isSelectedPayment = this.bankInfoTypeId != null;

        if (this.bankInfoSection) {
          isSelectedPayment = this.bankInfoId != null && this.bankInfoId.length > 0;
        }

        return isSelectedPayment;
      },
      canCreateWithPastOrFutureDate: function () {
        return this.$store.getters['Auth/checkPermission']('bill_cancreatewithpastorfuturedate') ? false : true;
      }
    },
    watch: {
      bankInfoTypeId: function () {
        this.getBankInfos();
        this.bankInfoId = '';
        this.bankInfoSection = false;
      },
      payRowTotal: function (val) {
        if (val) {
          this.payRowTotal = val.toString().replace(',', '.');
          this.calculatePayRowTotal();
        }
      }
    },
    mounted: function () {
      this.model.id = this.accountingDebtId;
      this.getDebt();
    }
  };
</script>
